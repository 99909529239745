.uninstall {
  min-height: 100vh;
  padding-bottom: 39px;
  padding-top: 39px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-height: 800px) {
    padding-top: 115px;
  }
  .wrap {
    max-width: 558px;
    text-align: center;

    .logo {
      margin-bottom: 73px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 103px;
      }
    }

    h1 {
      letter-spacing: -2px;
      font-size: 40px;
      line-height: 130%;
      font-weight: 400;
      margin-bottom: 56px;
    }

    p {
      font-size: 24px;
      line-height: 130%;
      letter-spacing: -1px;
    }

    .feedback-success {
      margin-top: 107px;
      font-size: 28px;
      line-height: 110%;
      text-align: center;
      letter-spacing: -0.03em;
      font-weight: 600;
    }

    .feedback-form {
      margin-top: 60px;

      &__input {
        margin-bottom: 6px;
      }

      .btn {
        margin-top: 34px;
      }
    }
  }
}

.feedbackForm {
  margin-top: 60px;

  input > span {
    margin-bottom: 16px;
  }

  button {
    margin-top: 34px;
  }
}

.loader {
  height: 305px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feedbackSuccess {
  margin-top: 107px;
  font-size: 28px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.03em;
  font-weight: 600;
  margin-bottom: 95px;
}
