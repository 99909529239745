.animation {
  overflow: hidden;
  font-family: "Aeonik", sans-serif;

  p {
    display: flex;
    flex-direction: column;

    &:global(.startAnimation) {
      animation: animationScroll 200ms linear;
      animation-fill-mode: forwards;
    }

    @keyframes animationScroll {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(var(--translate));
      }
    }
  }
}
