.wrap {
  max-width: 622px;
  text-align: center;

  .logo {
    margin: 0 auto 32px auto;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 103px;
    }
  }

  h1 {
    color: #14131e;
    font-size: 40px;
    line-height: 48px;
    font-weight: 600;
    margin-bottom: 12px;
  }

  .description {
    font-size: 24px;
    color: #555269;
    line-height: 32px;
    font-weight: 500;
  }

  .feedback-success {
    margin-top: 107px;
    font-size: 28px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.03em;
    font-weight: 600;
  }
}

.feedbackForm {
  margin-top: 40px;

  button {
    margin-top: 34px;
  }
}

.inputWrapper {
  margin-bottom: 16px;
}

.loader {
  height: 305px;
  display: flex;
  align-items: center;
  justify-content: center;
}
