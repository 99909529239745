.wrapper {
  max-width: 622px;
  width: 100%;
  text-align: center;
}

.check {
  margin: 0 auto;
}

.title {
  margin-top: -14px;
  color: #14131e;
  font-size: 40px;
  line-height: 48px;
  font-weight: 600;
  margin-bottom: 12px;
}

.subtitle {
  font-size: 24px;
  color: #555269;
  line-height: 32px;
  font-weight: 500;
}
