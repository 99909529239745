.wrap {
  background-color: #fafafa;
  width: 730px;
  height: 519px;
  display: flex;
  position: relative;
  scale: var(--scale);

  transition-property: margin, scale, transform;
  transition-duration: 600ms;
}

.pageTitle {
  display: flex;
  gap: 12px;
}

.pageContent {
  padding-top: 13px;
  flex: 1;
  display: flex;
  justify-content: center;
}
.pageCode {
  position: relative;
  overflow: hidden;

  .money {
    position: absolute !important;
    top: -10px;
    right: -28px;
    rotate: -7.38deg;
  }
}

.animation {
  position: absolute;
  top: 108px;
  left: 179px;
  z-index: 55;

  width: 21px;
  height: 24px;

  color: #1f380b;
  text-align: center;
  font-family: "Aeonik", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.406px;
}

.pageWrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
