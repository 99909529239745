.icon {
  margin-bottom: 16px !important;
}

.title {
  margin-bottom: 8px;
}
.description {
  margin-bottom: 40px;
}

.list {
  width: 100%;
  padding: 40px 32px;
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.07);
  margin-bottom: 56px;
}

.item {
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 42px;
  }
}

.listIcon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: rgba(131, 196, 40, 0.1) !important;
  color: #83c428 !important;
}

.itemText {
  color: #74718a;
  font-family: "Aeonik", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;

  b {
    color: #1f380b;
    font-weight: 700;
  }
}

.cguWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cgu {
  margin-bottom: 40px;
}
