:global(.offersToaster) {
  width: 387px;
  height: 260px;
  position: relative;
  overflow: hidden;
}

.wrap {
  position: absolute;
  padding: 12px 16px 16px 16px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  border-radius: 16px;
  border: 1px solid rgba(85, 82, 105, 0.5);
  background: rgba(11, 24, 74, 0.6);
  box-shadow: 0 4px 20px 0 rgba(15, 35, 112, 0.1);

  display: flex;
  flex-direction: column;

  transition: transform 600ms;
  transform: translateX(110%);

  &:global(.mounted) {
    transform: translateX(0);
  }
}

.header {
  margin-bottom: 8px;
}

.content {
  flex: 1;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
}

.contentHeader {
  padding: 12px 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: space-between;

  p {
    color: #545269;
    font-family: "Aeonik", sans-serif;
    font-size: 14px;

    line-height: normal;
    display: flex;
    gap: 4px;

    &:nth-child(1) {
      font-weight: 400;
    }
    &:nth-child(2) {
      color: #74718a;
      font-weight: 300;
    }
  }
}

.contentBody {
  position: relative;
  padding: 16px;
  overflow: hidden;
}

.title {
  color: #1f380b;
  font-family: "Aeonik", sans-serif;
  font-size: 21px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.42px;
  margin-bottom: 8px;

  span {
    color: #83c428;
  }
}

.description {
  color: #555269;
  font-family: "Aeonik", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 24px;
}

.button {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: #bae38b;
  color: #1f380b;
  font-family: "Aeonik", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;

  &.click {
    animation: animationClick 400ms linear;
  }

  @keyframes animationClick {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.6;
    }

    100% {
      opacity: 1;
    }
  }
}

.money {
  position: absolute;
  right: -4px;
  top: 10px;
}
