.button {
  font-size: 18px;
  line-height: 24px;
  color: #1f380b;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  border-radius: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 240px;
  background: #afe67f;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;

  &:not(:disabled):hover {
    background: #ceefb1;
  }

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }

  &:focus-visible {
    border-color: #1f380b;
  }
}
