:global(.browser) {
  width: 1440px;
  height: 926px;
  position: relative;
  overflow: hidden;
}

.wrap {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 10px;
  background-color: #f5f5f5;
  width: var(--width);
  transition: width 600ms;
}

.navigation {
  border-radius: 5px 5px 0 0;
  border: 0.5px solid #c5c5c5;
  background-color: #e8eaed;
  overflow: hidden;
}

.header,
.content {
  width: 100%;
}
