.wrapper {
  position: relative;
  padding-bottom: 20px;
  text-align: left;
}

.label {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
  color: #555269;
  display: inline-block;
}

.textarea {
  padding: 12px 16px;
  resize: none;
  width: 100%;
  height: 96px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #14131e;
  font-family: "Montserrat", sans-serif;

  &::placeholder {
    color: #9996ae;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  &:focus,
  &:focus-visible {
    border: 2px solid #83c428;
    padding: 11px 15px;
  }
}

.error {
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: #ca3f4b;
  font-family: "Montserrat", sans-serif;
}
