.inputText {
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  position: relative;

  &:global(.error) {
    .helpText {
      color: #ca3f4b;
    }
  }

  input {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    padding: 0 15px;
    pointer-events: auto;
    display: flex;
    color: #1f380b;
    font-family: "Aeonik", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: border-color 0.15s;

    &:focus,
    &:focus-visible {
      outline: none;
      padding: 0 14px;
      border: 2px solid #83c428;
    }

    &::placeholder {
      color: #74718a;
      font-family: "Aeonik", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
    }

    &:disabled {
      //cursor: default;
      //pointer-events: none;
      //background-color: ${({ theme }) => theme.colors.secondaryMinorColor};
      //color: ${({ theme }) => theme.colors.textSecondaryColor7};
      //border: none;
      //padding: 0 16px;
      opacity: 0.3;
    }
  }
}

.helpText {
  margin: 0;
  position: absolute;
  left: 0;
  bottom: calc(20px - 3px);
  transform: translateY(100%);
  color: #1f380b;
  font-family: "Aeonik", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
}
