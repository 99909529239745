.textarea {
  width: 100%;
  height: 117px;
  border: 2px solid #f6f7f7;
  background-color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 19px 30px;
  resize: none;
  font: 18px "Circular";
  font-weight: 400;

  color: #002a5c;

  &.textareaError {
    color: #d1352a;
    border: 2px solid #d1352a;
  }

  &::placeholder {
    font: 18px "Circular";
    color: #a7a7b1;
    font-weight: 400;
    letter-spacing: -0.65px;
  }
}

.textareaErrorText {
  text-align: left;
  margin-bottom: 23px;
  color: #d1352a;
  font-size: 18px !important;
  line-height: 150%;
  display: block;
  width: 100%;
}
