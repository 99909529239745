.icon {
  margin-bottom: 16px !important;
}

.title {
  margin-bottom: 16px;
}

.description {
  margin-bottom: 32px;
}

.backdrop {
  position: relative;
  width: 100%;
  height: 470px;
  border-radius: 10px;
  //overflow: hidden;
}

.backdropOffers {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 470px;

  opacity: 1;
  transition: opacity 600ms;

  &:global(.unmounted) {
    opacity: 0;
  }

  :global(.browser) {
    width: 730px;
    height: 470px;

    &:global(.step_1) {
      transform: translate(-56px, 39px);
    }

    &:global(.step_2) {
      transform: translate(-222px, 22px);
    }
  }
  :global(.offersToaster) {
    position: absolute;
    top: 121px;
    right: 24px;
  }
}

.backdropApply {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 470px;

  opacity: 0;
  scale: 0.6;

  transition-property: opacity, scale;
  transition-duration: 600ms;

  &:global(.mounted) {
    opacity: 1;
    scale: 1;
    transition-delay: 600ms;
  }
}

.cursor {
  position: absolute;
  top: 0;
  right: 0;
  transition: transform 600ms;

  &:global(.step_1) {
    transform: translate(-508px, 405px);
  }
  &:global(.step_4) {
    transform: translate(-76px, 317px);
  }
}

.popup {
  position: absolute;
  width: 320px;
  height: 311px;
  top: 107px;
  right: 333px;
  opacity: 0;
  object-fit: cover;
  pointer-events: none;
  transition: opacity 300ms;

  &.popup_1 {
    &:global(.step_3) {
      opacity: 1;
      pointer-events: auto;
    }
  }
  &.popup_2 {
    &:global(.step_5) {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.bottomText {
  position: relative;
  width: 100%;
  margin-top: 24px;
  height: 60px;
}

.textWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  width: 100%;
  color: #1f380b;
  font-family: "Aeonik", sans-serif;
  font-size: 21px;
  font-weight: 700;
  line-height: 120%;

  p span {
    color: #83c428;
    margin-right: 8px;
  }

  .button {
    margin: 0 0 0 16px;
  }

  .restart {
    position: relative;
    cursor: pointer;
    opacity: 1;
    &:hover {
      transition: opacity, 250ms;
      opacity: 0.7;
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 42px;
    }
  }

  opacity: 0;
  transform: translateY(100%);
  transition:
    transform 600ms,
    opacity 600ms;

  &:global(.mounted) {
    opacity: 1;
    transform: translateY(0);
  }
  &:global(.unmounted) {
    opacity: 0;
    transform: translateY(-100%);
  }
}
