.title {
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  color: #121e6f;
  margin-bottom: 17px;
  max-width: 395px;
}
.descr {
  font-size: 16px;
  line-height: 25px;
  color: #28347d;
  max-width: 395px;
  margin-bottom: 57px;
}
.categoryTitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #28347d;
  margin-bottom: 2px;
}
.category {
  margin-bottom: 42px;
}
.rowField {
  position: relative;
  width: 100%;
  margin-bottom: 12px;
  max-width: 350px;
}
.inputField {
  max-width: 350px;
  background: #f9f9f9;
  width: 100%;
  border: 1px solid #7891ff;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 10px;
  height: 44px;
  color: #121e6f;
  font-size: 15px;
  line-height: 22px;
  &:focus + .label {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
  &:disabled {
    border-radius: 0;
    border: none;
    background-color: #fff;
    pointer-events: none;
    cursor: default;
  }
}
.removeButton {
  position: absolute;
  right: 0;
  background: none;
  border: none;
  outline: none;
  height: 44px;
  width: 44px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  transition: 0.25s;

  &:hover {
    opacity: 0.6;
    transition: 0.25s;
  }
}
.label {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: #abaaaa;
  cursor: text;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  &.empty {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  &.disabledLabel {
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.41px;
    color: #000000;
    font-weight: 300;
    cursor: default;
    pointer-events: none;
  }
}

.addCategory {
  margin-bottom: 98px;
  @media (max-width: 1024px) {
    margin-bottom: 0px;
  }
}
.addBtn {
  max-width: 350px;
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: #6e6e6e;
  background: #ffffff;
  border: 1px dashed #989898;
  padding: 0 32px;
  cursor: pointer;
  transition: 0.15s;
  &:hover {
    opacity: 0.6;
  }
  span {
    position: relative;
    &:before {
      content: "+";
      position: absolute;
      top: 50%;
      left: -20px;
      transform: translate(0, -50%);
    }
  }
  &:disabled {
    cursor: default;
    pointer-events: none;
  }
}

.check {
  position: relative;
  max-width: 350px;
  width: 100%;
  background: #ffffff;
  border-radius: 20px;
  padding: 11px 36px 11px 29px;
  margin-bottom: 47px;
}
.checkRow {
  &:first-child {
    margin-bottom: 5px;
  }
  label {
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    color: #121e6f;
    transition: 0s;
    &:not(&.activeCheck):hover {
      opacity: 0.6;
    }
    &.activeCheck:hover {
      cursor: default;
    }
  }
}
.customCheck {
  position: absolute;
  z-index: -1;
  opacity: 0;
  cursor: pointer;
  & + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    &.activeCheck {
      font-weight: 700;
    }
    &:before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      flex-grow: 0;
      background: #ffffff;
      border: 1px solid #9cb6cc;
      box-sizing: border-box;
      border-radius: 50px;
      margin-right: 16px;
      transition: 0.15s;
    }
  }
  &:checked + label::before {
    border: none;
    background: #f53689;
  }
}

.send {
  max-width: 350px;
  width: 100%;
  height: 68px;
  border-radius: 12px;
  cursor: pointer;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  text-align: center;
  background: #d1419d;
  box-shadow:
    0px 20px 13px rgba(0, 0, 0, 0.035),
    0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0274815),
    0px 1.85185px 3.14815px rgba(0, 0, 0, 0.0168519);
  transition: 0.15s;
  &:hover {
    opacity: 0.6;
  }
  span {
    display: inline-block;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    max-width: 243px;
    color: #ffffff;
    margin: 0 auto;
  }
  &:disabled {
    background: #d1419d;
    opacity: 0.5;
    box-shadow:
      0px 20px 13px rgba(0, 0, 0, 0.035),
      0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0274815),
      0px 1.85185px 3.14815px rgba(0, 0, 0, 0.0168519);
    cursor: default;
    pointer-events: none;
  }
}
.btnIcon {
  position: absolute;
  top: 28px;
  right: 24px;
  width: 24px;
  height: 24px;
  a {
    transition: 0.15s;
    display: block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.3333 0L17.724 4.39067L8.39062 13.724L10.276 15.6093L19.6093 6.276L24 10.6667V0H13.3333Z' fill='%2381A9CB'/%3E%3Cpath d='M21.3333 21.3333H2.66667V2.66667H12L9.33333 0H2.66667C1.196 0 0 1.196 0 2.66667V21.3333C0 22.804 1.196 24 2.66667 24H21.3333C22.804 24 24 22.804 24 21.3333V14.6667L21.3333 12V21.3333Z' fill='%2381A9CB'/%3E%3C/svg%3E%0A");
  }
  &:hover {
    a {
      opacity: 0.7;
    }
  }
}
