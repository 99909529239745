.inputCheckbox {
  position: relative;
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
  }

  &:global(.checked) {
    .customCheckbox {
      border-color: #83c428;
      background-color: #83c428;
    }
  }
}

.iconWrap {
  position: relative;
  margin-right: 10px;
  cursor: pointer;

  &:hover:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    width: 32px;
    height: 32px;
    background-color: rgba(112, 178, 206, 0.1);
    border-radius: 50%;
  }
}

.customCheckbox {
  position: relative;
  z-index: 2;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.labelText {
  color: #74718a;
  font-family: "Aeonik", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;

  a {
    position: relative;
    z-index: 10;
    color: #83c428;
    text-decoration-line: underline;
    opacity: 1;
    transition: opacity 250ms;

    &:hover {
      opacity: 0.6;
    }
  }
}
