.mainButton {
  position: relative;
  width: var(--width);
  min-height: 60px;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1f380b;
  border-radius: 40px;
  margin: 0 auto;

  transition-property: opacity, background-color, border-color, color;
  transition-duration: 250ms;

  &:global(.main) {
    background-color: #afe67f;
    &:hover,
    &:global(.hover) {
      background-color: #a3d676;
    }
  }

  &:global(.outline) {
    background-color: #ffffff;
    border: 2px solid #83c428;
    &:hover,
    &:global(.hover) {
      background-color: #afe67f;
      border: 2px solid #afe67f;
    }
  }

  &:global(.dark) {
    background-color: #a3d676;
    border: 2px solid #a3d676;
    &:hover,
    &:global(.hover) {
      color: #afe67f;
      background-color: #1f380b;
      border: 2px solid #1f380b;
    }
  }

  &:global(.disabled) {
    pointer-events: none;
    opacity: 0.3;
  }
}

.buttonText {
  font-family: "Aeonik", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
}
