// Aeonik

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik/Aeonik-Black.woff2") format("truetype");
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik/Aeonik-Bold.woff2") format("truetype");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik/Aeonik-Medium.woff2") format("truetype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Aeonik";
  src: url("fonts/Aeonik/Aeonik-Regular.woff2") format("truetype");
  font-weight: 400;
  font-display: swap;
}

// Montserrat

@font-face {
  font-family: "Montserrat";
  src:
    local("Montserrat Regular"),
    local("Montserrat-Regular"),
    url("fonts/Montserrat/Montserrat-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src:
    local("Montserrat Medium"),
    local("Montserrat-Medium"),
    url("fonts/Montserrat/Montserrat-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src:
    local("Montserrat SemiBold"),
    local("Montserrat-SemiBold"),
    url("fonts/Montserrat/Montserrat-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src:
    local("Montserrat Bold"),
    local("Montserrat-Bold"),
    url("fonts/Montserrat/Montserrat-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
}
