.description {
  color: #74718a;
  text-align: center;
  font-family: "Aeonik", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  span {
    font-weight: 700;
  }
}
