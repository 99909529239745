.root {
  display: flex;
  position: relative;
  max-width: 1280px;
  @media (min-width: 1440px) {
    margin: 0 auto;
  }
  @media (max-height: 950px) {
    padding-bottom: 50px;
  }
}
.images {
  position: absolute;
  padding-top: 25px;
  transform: translateX(-49%);
  img {
    max-width: 100%;
  }
  @media (max-width: 1440px) {
    transform: translateX(-38%);
  }
  @media (max-width: 1300px) {
    transform: translateX(-45%);
  }

  @media (max-width: 1230px) {
    img {
      max-width: 100%;
    }
  }
  @media (max-width: 1040px) {
    transform: translateX(-50%);
  }
}
.content {
  margin-left: auto;
  max-width: 537px;
  width: 100%;
  @media (max-width: 1440px) {
    padding-right: 15px;
  }
  @media (max-width: 1230px) {
    padding-right: 0;
    padding-left: 5px;
  }
  @media (max-width: 1040px) {
    margin-right: 20px;
  }
}
.title {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  color: #121e6f;
  margin-bottom: 57px;
  word-spacing: 0px;
  letter-spacing: 0px;
  white-space: nowrap;
  @media (max-width: 1350px) {
    margin-left: -5px;
  }
}
.body {
  max-width: 600px;
}
.list {
  margin-bottom: 40px;
  li {
    position: relative;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    color: #28347d;
    padding-left: 25px;
    margin-bottom: 25px;
    letter-spacing: 0.05px;
    max-width: 330px;
    &:nth-child(3) {
      max-width: 320px;
    }
    &:last-child {
      max-width: 265px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 8px;
      left: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #d1419d;
    }
  }
}
.check {
  max-width: 350px;
  margin-bottom: 23px;
}
.firstCheckbox,
.secondCheckbox {
  padding: 9px 10px 10px 20px;
  height: 44px;
  border: 1px solid #becee1;
  box-sizing: border-box;
  border-radius: 8px;
  label {
    span {
      display: inline-block;
      position: relative;
      padding: 0 0 0 35px;
      font-family: Montserrat;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.01em;
      color: #121e6f;
      cursor: pointer;
      text-replace: 0.15s;
      &:hover {
        opacity: 0.6;
        &:before {
          border: 1px solid #96a3b2;
        }
      }
      &:before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 0;
        transition: 0.15s;
        border: 1px solid #becee1;
        box-sizing: border-box;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
}
.firstCheckbox {
  margin-bottom: 23px;
}
.secondCheckbox {
  display: flex;
  align-items: center;
  label {
    display: flex;
    align-items: flex-start;
  }
}
.checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
  display: block;
  width: 0;
  height: 0;
  &:checked {
    + span:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      background-image: url("data:image/svg+xml,%3Csvg role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23121E6F' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 100%;
      cursor: pointer;
    }
  }
}
.checkLink {
  display: inline-block;
  padding-left: 18px;
  padding-top: 5px;
  transition: 0.15s;
  &:hover {
    opacity: 0.6;
  }
}
.info {
  font-family: Montserrat;
  font-size: 13px;
  line-height: 25px;
  color: #28347d;
  margin-bottom: 38px;
  letter-spacing: 0.01px;
}
.button {
}
.btn {
  text-align: center;
  display: inline-block;
  max-width: 369px;
  width: 100%;
  height: 64px;
  display: flex;
  border: 0;
  justify-content: center;
  align-items: center;
  background: #d1419d;
  box-shadow:
    0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0531481),
    0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0425185),
    0px 20px 13px rgba(0, 0, 0, 0.035),
    0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0274815),
    0px 1.85185px 3.14815px rgba(0, 0, 0, 0.0168519);
  border-radius: 12px;
  cursor: pointer;
  transition: 0.15s;
  &:hover {
    opacity: 0.6;
  }
  span {
    font-family: Montserrat;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: #ffffff;
    max-width: 251px;
  }
  &.disabled {
    opacity: 0.1;
    cursor: default;
    pointer-events: none;
  }
}
