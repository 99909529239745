*,
*::before,
*::after {
  box-sizing: border-box;
}
/*----------------------------------------------------------------------------------*/
/*------------------------------RESET DEFAULT STYLES--------------------------------*/
/*----------------------------------------------------------------------------------*/
html,
body {
  width: 100%;
  height: 100%;
}
html {
  -webkit-text-size-adjust: none;
}
body {
  padding: 0;
  margin: 0;
  font:
    17px "Aeonik",
    sans-serif;
  background: #fff;
  color: #000000;
}
select,
input,
textarea {
  font-size: 17px;
  vertical-align: middle;
}
textarea {
  vertical-align: top;
}
:focus {
  outline: none;
}
input[type="submit"] {
  cursor: pointer;
}
img,
fieldset {
  border: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a:link,
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
/*----------------------------------------------------------------------------------*/
/*-------------------------------------HTML5 BLOCKS---------------------------------*/
/*----------------------------------------------------------------------------------*/
header,
footer,
nav,
section,
article,
aside,
main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

html {
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
  min-width: 360px;
}

body {
  font-family: "Aeonik", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #74718a;
  margin: 0;
  background-color: #ffffff;
}

a {
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: currentColor;

  &:focus {
    outline: none;
    //outline-color: var(--secondary-color);
  }
}

ul {
  font-size: 100%;
  font: inherit;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

table,
tr,
td {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  cursor: pointer;
  appearance: none;

  &:focus {
    outline: none;
  }
}
input {
  border: none;
  outline: none;
  border-radius: 0;
  font: inherit;
  font-size: 100%;
  line-height: 100%;
  appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--primary-bg-color) inset !important;
}

select {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 0;
  background-color: transparent;
  font: inherit;
  font-size: 100%;
  line-height: 100%;
}

svg {
  display: block;
}
