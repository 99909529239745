.icon {
  margin-bottom: 24px !important;
}

.title {
  margin-bottom: 32px;
}

.description {
  margin-bottom: 56px;
}
.input {
  max-width: 370px;
  margin-bottom: 20px;
}

.label {
  padding: 8px 24px;
  margin-bottom: 16px;
  border-radius: 24px;
  background-color: rgba(131, 196, 40, 0.1);
  color: #1f380b;
  text-align: center;
  font-family: "Aeonik", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
}
