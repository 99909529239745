.root {
  display: flex;
  position: relative;
  max-width: 1280px;
  height: 635px;
  @media (min-width: 1440px) {
    margin: 0 auto;
  }
  @media (max-height: 950px) {
    padding-bottom: 50px;
  }
}

.firstPageDecor {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 312px;
  top: 166px;
  right: 530px;
  z-index: 2;

  img {
    position: relative;
  }

  img:first-of-type {
    width: 393px;
    height: 275px;
  }

  img:nth-of-type(2) {
    top: -170px;
    width: 393px;
    height: 276px;
  }

  img:nth-of-type(3) {
    top: -340px;
    width: 393px;
    height: 281px;
  }
}

.secondPageDecor {
  position: absolute;
  z-index: 2;
  top: -210px;
  right: 727px;
  width: 280px;
  height: 500px;

  img {
    position: absolute;
    transform: scale(0.5);
    left: -280px;

    &:first-of-type {
      z-index: 2;
      left: -160px;
      top: 178px;
    }
  }
}

.image {
  padding-top: 18px;
  position: absolute;
  transform: translateX(-23%);
  img {
    max-width: 100% !important;
  }
  @media (max-width: 1440px) {
    transform: translateX(-23%);
  }
  @media (max-width: 1250px) {
    transform: translateX(-30%);
  }
  @media (max-width: 1200px) {
    transform: translateX(-35%);
  }
  @media (max-width: 1140px) {
    transform: translateX(-45%);
  }
}
.imageSecond {
  padding-top: 18px;
  position: absolute;
  transform: translateX(-41%);
  img {
    max-width: 100% !important;
  }
  @media (max-width: 1440px) {
    transform: translateX(-41%);
  }
  @media (max-width: 1250px) {
    transform: translateX(-48%);
  }
  @media (max-width: 1200px) {
    transform: translateX(-53%);
  }
  @media (max-width: 1140px) {
    transform: translateX(-53%);
  }
}

.imageSecondSafari {
  padding-top: 18px;
  position: absolute;
  transform: translateX(-29%);
  img {
    max-width: 100% !important;
  }
  @media (max-width: 1440px) {
    transform: translateX(-29%);
  }
  @media (max-width: 1250px) {
    transform: translateX(-37%);
  }
  @media (max-width: 1200px) {
    transform: translateX(-45%);
  }
  @media (max-width: 1140px) {
    transform: translateX(-50%);
  }
}

.imageThird {
  padding-top: 18px;
  position: absolute;
  transform: translateX(-39%);
  img {
    max-width: 100% !important;
  }
  @media (max-width: 1440px) {
    transform: translateX(-39%);
  }
  @media (max-width: 1250px) {
    transform: translateX(-46%);
  }
  @media (max-width: 1200px) {
    transform: translateX(-51%);
  }
  @media (max-width: 1140px) {
    transform: translateX(-51%);
  }
}
.content {
  margin-left: auto;
  max-width: 540px;
  margin-top: 30px;
  position: relative;
  padding-right: 10px;
}
.title {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  color: #121e6f;
  margin-bottom: 24px;
  word-spacing: 0px;
  //letter-spacing: 0px;
  letter-spacing: -0.4px;

  i {
    color: #d1419d;
    font-style: initial;
  }
}
.body {
  margin-left: 13px;
  max-width: 440px;
  margin-bottom: 115px;
}
.typography {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24.5px;
  color: #28347d;
  margin-bottom: 13px;
  span {
    font-size: 14px;
    text-decoration-line: underline;
    color: #0d83f0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  a {
    color: #0d83f0;
    font-size: 14px;
    text-decoration: underline !important;
    &:hover {
      color: #165a93;
    }
  }
}
.button {
}
.btn {
  display: inline-block;
  max-width: 369px;
  width: 100%;
  height: 65px;
  display: flex;
  border: 0;
  justify-content: center;
  align-items: center;
  background: #d1419d;
  box-shadow:
    0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0531481),
    0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0425185),
    0px 20px 13px rgba(0, 0, 0, 0.035),
    0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0274815),
    0px 1.85185px 3.14815px rgba(0, 0, 0, 0.0168519);
  border-radius: 12px;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  cursor: pointer;
}

.buttons {
  display: flex;
  .btn {
    transition: 0.15s;
  }
  .btn:hover {
    opacity: 0.6;
  }
  .btn:first-of-type {
    width: 148px;
    font-size: 16px;
    height: 64px;
    margin-right: 43px;
  }

  .btn:nth-of-type(2) {
    width: 148px;
    font-size: 16px;
    height: 64px;
    margin-right: 43px;
    background: #0d83f0;
  }
}

a.underlineAction {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 25px;
  text-decoration: underline !important;
  color: #424661;
  margin-top: 48px;
  display: block;
  width: fit-content;
  transition: 0.15s;
  &:hover {
    opacity: 0.6;
  }
}

.firstText {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #28347d;
  margin-bottom: 50px;
}

.secondText {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 25px;
  color: #28347d;
  margin-bottom: 41px;
}

a.blueAction {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 25px;
  text-decoration: underline !important;
  color: #0d83f0;
  transition: 0.15s;
  &:hover {
    opacity: 0.6;
  }
}

.secondStepFirstText {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #28347d;
  margin-bottom: 24px;
  margin-top: 57px;
}

//ul {
//    margin-bottom: 62px;
//
//    li {
//        font-family: Montserrat;
//        font-style: normal;
//        font-weight: normal;
//        font-size: 16px;
//        line-height: 25px;
//        color: #28347D;
//        display: flex;
//        align-items: center;
//        margin-bottom: 25px;
//
//        span:first-of-type {
//            background: #D1419D;
//            width: 24px;
//            height: 24px;
//            border-radius: 50%;
//            display: flex;
//            justify-content: center;
//            align-items: center;
//            font-family: Montserrat;
//            font-style: normal;
//            font-weight: 600;
//            font-size: 14px;
//            line-height: 25px;
//            color: #fff;
//            margin-right: 15px;
//            flex-shrink: 0;
//        }
//    }
//}

.videoWrapper {
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;

  button {
    background: rgba(11, 11, 11, 0.7);
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    margin-left: auto;
    cursor: pointer;
  }

  div {
    width: 100%;
    height: 100%;
    max-width: 1100px;
    max-height: 654px;

    video {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.notOrderedList {
  padding-left: 13px;
  margin-bottom: 113px;

  li {
    display: flex;
    margin-bottom: 29px;
    font-size: 16px;
    font-family: Montserrat;

    span {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: #121e6f;
      margin-top: 8px;
      margin-right: 11px;
    }
  }
}

.inputWrapper {
  label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #28347d;
    display: block;
  }

  input {
    background: #f9f9f9;
    border: 1px solid #becee1;
    box-sizing: border-box;
    border-radius: 8px;
    height: 44px;
    width: 293px;
    padding: 0 16px;
    font-family: Montserrat;
    color: #28347d;
    &:focus {
      border: 1px solid #0d83f0;
    }
  }
}

.error,
.note {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.08px;
  color: #424661;
  margin-top: 8px;
}

.error {
  color: #cb5252;
}

.accept {
  border: 1px solid #becee1;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 32px 38px;
  max-width: 475px;
  margin-top: 86px;

  .acceptCheckbox {
    background: none;
    box-shadow: none;
    border: 0;
    display: flex;
    cursor: pointer;
    transition: 0.15s;
    &:hover {
      p {
        opacity: 0.6;
      }
    }
    span {
      width: 28px;
      height: 28px;
      border: 1px solid #becee1;
      box-sizing: border-box;
      border-radius: 4px;
      flex-shrink: 0;
      margin-right: 26px;
    }

    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #121e6f;
      text-align: left;
      margin-right: 15px;
    }
    a {
      transition: 0.15s;
      &:hover {
        opacity: 0.6;
      }
    }
  }

  .btn {
    width: 100%;
    max-width: 100%;
    margin-top: 32px;

    &:disabled {
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
    }
  }
}
