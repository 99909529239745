.uninstall {
  min-height: 100vh;
  padding-bottom: 88px;
  padding-top: 88px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
}
