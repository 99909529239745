.button {
  width: 100%;
  height: 72px;
  text-align: center;
  font-size: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.65px;

  &-primary {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid #001a38;
    background-color: #002a5c;
    color: #fff;

    &:disabled {
      opacity: 0.3;
    }

    &:disabled:hover {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  &-outlined {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 2px solid #f6f7f7;
    background-color: #fff;
    color: #002a5c;
    font-weight: 500;
    font-size: 18px;
    height: 62px;
    line-height: 62px;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
}
