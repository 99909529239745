.item {
  flex: 1;
  display: flex;
  position: relative;
  margin-top: 40px;
  background: #fff;

  &:global(.min) {
    .icon {
      background-color: rgba(223, 29, 23, 0.07);
      color: #df1d17;
    }
  }
  &:global(.optimal) {
    .icon {
      background-color: rgba(157, 154, 159, 0.1);
      color: #6c676e;
    }
  }

  &:global(.max) {
    .icon {
      background-color: rgba(25, 162, 90, 0.1);
      color: #19a25a;
    }
  }

  &:global(.active) {
    .content {
      padding-top: 32px;
      border-top: none;
      border-radius: 0 0 24px 24px;
      border-color: #83c428;
    }
  }

  &:global(.hover) {
    cursor: pointer;
    .content {
      border-color: #83c428;
      background-color: rgba(131, 196, 40, 0.07);
    }
  }
}
.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 40px 32px 40px;
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  transition:
    background 250ms,
    border-color 250ms;
}

.header {
  position: absolute;
  left: 0;
  top: -40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 24px 24px 0 0;
  background: #1f380b;
  color: #fff;
  font-family: "Aeonik", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
}

.icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 12px;
}

.title {
  color: #1f380b;
  text-align: center;
  font-family: "Aeonik", sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 32px;
}

.advantagesList {
  flex: 1;
  padding-top: 24px;
  margin-bottom: 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.advantageItem {
  color: #74718a;
  font-family: "Aeonik", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.36px;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.advantageIcon {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  color: #ffffff;

  &:global(.cross) {
    background-color: #df1d17ff;
  }
  &:global(.check) {
    background-color: #19a25a;
  }
}
