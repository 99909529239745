.input {
  width: 100%;
  height: 62px;
  border: 2px solid #f6f7f7;
  background-color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 19px 30px;
  font-family: "Aeonik", sans-serif;
  color: #1f380b;
  font-size: 18px;
  font-weight: 500;

  &.inputError {
    color: #ca3f4b;
    border: 2px solid #ca3f4b;
  }

  &[type="password"] {
    letter-spacing: 8px;
  }

  &::placeholder {
    color: #a7a7b1;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.65px;
  }
}
.inputErrorText {
  text-align: left;
  margin-bottom: 23px;
  color: #d1352a;
  font-size: 18px !important;
  line-height: 150%;
  display: block;
  width: 100%;
}
