.wrap {
  padding: 0 21px;
  background: #ffffff;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  scale: var(--scale);
  transition:
    scale 600ms,
    margin 600ms;
  margin: calc((40px * var(--scale) - 40px) / 2)
    calc((1440px * var(--scale) - 1440px) / 2);

  svg {
    position: relative;
    &:before {
      opacity: 0;
    }
  }
}

.input {
  margin: 0 8px;
  padding: 4px 8px;
  flex: 1;
  border-radius: 10px;
  background: #f1f3f4;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu {
  display: flex;
  align-items: center;
  gap: 19px;
  color: #5f6368;
}

.active {
  transition: scale 300ms;
  scale: 1.5;
}

.hover {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #e2e2e2;
    opacity: 1;
    transition: opacity 3000ms;
  }
}

.disabled {
  display: none;
}
