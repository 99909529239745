.icon {
  margin: 25px !important;
}

.titleText {
  color: #83c428;
  text-align: center;
  font-family: "Aeonik", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 16px;
}
.title {
  font-size: 28px;
  margin-bottom: 32px;
}
.description {
  margin-bottom: 40px;
}
