.icon {
  margin-bottom: 16px !important;
}

.title {
  margin-bottom: 40px;
}

.description {
  margin-top: 24px;

  opacity: 0;
  transition: opacity 600ms;

  &:global(.mounted) {
    opacity: 1;
  }
  &:global(.unmounted) {
    opacity: 0;
  }
}

.pageContainer {
  position: relative;
  width: 730px;
  height: 470px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.profilePage {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0, 0);

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #f3f9e9;
    opacity: 0;
    transition: opacity 600ms;
  }

  &:global(.step_2) {
    transform-origin: 230px 10px;

    &:after {
      opacity: 1;
    }
  }
}

.bottomText {
  position: relative;
  width: 100%;
  margin-top: 24px;
  height: 60px;
}

.textWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  width: 100%;
  color: #1f380b;
  font-family: "Aeonik", sans-serif;
  font-size: 21px;
  font-weight: 700;
  line-height: 120%;

  p span {
    color: #83c428;
    margin-right: 8px;
  }

  .button {
    margin: 0 0 0 16px;
  }

  .restart {
    position: relative;
    cursor: pointer;
    opacity: 1;
    &:hover {
      transition: opacity, 250ms;
      opacity: 0.7;
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 42px;
    }
  }

  opacity: 0;
  transform: translateY(100%);
  transition:
    transform 600ms,
    opacity 600ms;

  &:global(.mounted) {
    opacity: 1;
    transform: translateY(0);
  }
  &:global(.unmounted) {
    opacity: 0;
    transform: translateY(-100%);
  }
}
