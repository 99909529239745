:global(.applyToaster) {
  width: 376px;
  margin: 0 auto;
  height: 100%;
  position: relative;
}

.wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  border-radius: 14px;
  background: #ffffff;
  box-shadow: 0 3.3822011947631836px 16.911006927490234px 0px rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
}

.header {
  padding: 8px 8px 8px 16px;
  border-bottom: 0.846px solid rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: space-between;

  p {
    color: #545269;
    font-family: "Aeonik", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }
}

.content {
  flex: 1;
  padding: 7px 20px 45px 20px;
  background: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentHeader {
  padding: 12px 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: space-between;

  p {
    color: #545269;
    font-family: "Aeonik", sans-serif;
    font-size: 14px;

    line-height: normal;
    display: flex;
    gap: 4px;

    &:nth-child(1) {
      font-weight: 400;
    }
    &:nth-child(2) {
      color: #74718a;
      font-weight: 300;
    }
  }
}

.contentBody {
  width: 100%;
  position: relative;
  padding: 14px 20px;
  overflow: hidden;

  border-radius: 13.529px;
  border: 0.846px solid rgba(0, 0, 0, 0.1);
}

.contentList {
  margin-bottom: 14px;
  padding-bottom: 14px;

  border-bottom: 0.846px solid rgba(0, 0, 0, 0.1);
}
.contentItem {
  display: flex;
  justify-content: space-between;
  color: #74718a;
  font-family: "Aeonik", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.304px;
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .price {
    font-weight: 700;
    color: #555269;
  }
  .discount {
    font-weight: 700;
    color: #83c428;
  }

  .coupon {
    padding: 3px 6px;
    margin-right: 6.5px;
    color: #14131e;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    border-radius: 5px;
    border: 0.6px solid rgba(31, 56, 11, 0.1);
    background: rgba(186, 227, 139, 0.3);
  }
  .cashback {
    padding: 2px 6px;
    border-radius: 5.073px;
    background: #1f380b;
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.237px;
  }
}

.title {
  color: #1f380b;
  font-family: "Aeonik", sans-serif;
  font-size: 21px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.42px;
  margin-bottom: 14px;
  margin-top: 8px;

  span {
    color: #83c428;
  }
}

.description {
  color: #74718a;
  text-align: center;
  font-family: "Aeonik", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 3px;
}

.finalPrice {
  color: #1f380b;
  text-align: center;
  font-family: "Aeonik", sans-serif;
  font-size: 27px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.541px;
}

.footer {
  margin-top: 27px;
  color: #74718a;
  font-family: "Aeonik", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;

  span {
    color: #83c428;
  }
}
