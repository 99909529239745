.wrap {
  width: 523px;
  height: 395px;
  position: relative;
  overflow: hidden;
  scale: var(--scale);
  margin: var(--margin);

  transition-property: scale, margin;
  transition-duration: 600ms;
}

.money {
  position: relative;
  z-index: 2;
}

.coin {
  position: absolute;
  top: 0;
  right: 0;
  transition: transform 600ms;

  &.coin7 {
    transform: translate(20px, 184px);
    &:global(.animation) {
      transform: translate(-30px, 180px);
    }
  }
  &.coin9 {
    transform: translate(-304px, 132px);
    &:global(.animation) {
      transform: translate(-376px, 51px);
    }
  }

  &.coin10 {
    z-index: 3;
    transform: translate(20px, 212px);
    &:global(.animation) {
      transform: translate(-193px, 257px);
    }
  }

  &.coin11 {
    transform: translate(-241px, 90px);
    &:global(.animation) {
      transform: translate(-234px, 40px);
    }
  }

  &.coin12 {
    transform: translate(-306px, 161px);
    &:global(.animation) {
      transform: translate(-345px, 228px);
    }
  }
}

.sparkle1 {
  position: absolute;
  top: 18px;
  left: 135px;
  opacity: 0.4;
}
.sparkle2 {
  position: absolute;
  top: 75px;
  right: 47px;
  opacity: 0.4;
}
.sparkle3 {
  position: absolute;
  bottom: 0;
  right: 59px;
  opacity: 0.4;
}
