.icon {
  margin-bottom: 16px !important;
}

.title {
  margin-bottom: 16px;
}

.description {
  margin-bottom: 32px;
}

.backdrop {
  position: relative;
  width: 100%;
  min-height: 469px;
  border-radius: 32px;
  background-color: rgba(131, 196, 40, 0.1);
  overflow: hidden;
}

.FV {
  position: absolute;
  top: 62px;
  right: 156px;
  z-index: 10;
  transition: scale 300ms;
  scale: 1;

  &:global(.mounted) {
    scale: 1.5;
  }
}

.test {
  position: absolute;
  top: 0;
  right: 0;

  transition-property: transform, width, height;
  transition-duration: 600ms;

  &:global(.step_1) {
    transform: translate(-56px, 39px);
  }

  &:global(.step_2) {
    transform: translate(-222px, 22px);
  }

  :global(.browser) {
    transition-property: width, height, opacity;
    transition-duration: 600ms;
    &:global(.step_1) {
      width: 674px;
      height: 418px;
    }

    &:global(.step_2) {
      width: 286px;
      height: 90px;
    }
    &:global(.step_6) {
      opacity: 0.6;
    }
  }
}

.cursor {
  position: absolute;
  top: 0;
  right: 0;
  transition: transform 600ms;

  &:global(.step_1) {
    transform: translate(-148px, 180px);
  }
  &:global(.step_2) {
    transform: translate(-332px, 100px);
  }
  &:global(.step_4) {
    transform: translate(-372px, 316px);
  }
  &:global(.step_6) {
    transform: translate(-359px, 97px);
  }
}

.popup {
  position: absolute;
  width: 320px;
  height: 311px;
  top: 107px;
  right: 333px;
  opacity: 0;
  object-fit: cover;
  pointer-events: none;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);

  transition: opacity, 600ms;

  &.popup_1 {
    &:global(.step_3) {
      opacity: 1;
    }
  }
  &.popup_2 {
    &:global(.step_5) {
      opacity: 1;
    }
  }

  &:global(.step_6) {
    opacity: 0.6 !important;
    box-shadow: none;
  }
}

.browserText {
  position: absolute;
  top: 149px;
  right: 70px;
  display: block;
  max-width: 220px;
  color: #1f380b;
  font-family: "Aeonik", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;

  opacity: 0;
  transition: opacity 300ms;

  &:global(.mounted) {
    opacity: 1;
  }
}

.bottomText {
  position: relative;
  width: 100%;
  margin-top: 24px;
  height: 60px;
}

.textWrap {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  color: #1f380b;
  font-family: "Aeonik", sans-serif;
  font-size: 21px;
  font-weight: 700;
  line-height: 120%;

  p span {
    color: #83c428;
    margin-right: 8px;
  }

  .button {
    margin: 0 0 0 16px;
  }

  .restart {
    position: relative;
    cursor: pointer;
    opacity: 1;
    &:hover {
      transition: opacity, 250ms;
      opacity: 0.7;
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 42px;
    }
  }

  opacity: 0;
  transform: translateY(100%);
  transition:
    transform 600ms,
    opacity 600ms;

  &:global(.mounted) {
    opacity: 1;
    transform: translateY(0);
  }
  &:global(.unmounted) {
    opacity: 0;
    transform: translateY(-100%);
  }
}
