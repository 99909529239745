body {
  height: 100vh;
  overflow: scroll;
  min-width: 1440px;
  @media (max-width: 1440px) {
    min-width: 1024px;
  }
}

#root {
  height: 100%;
  //display: flex;
  //align-items: center;

  &.uninstall {
    @media (max-height: 820px) {
      overflow-x: hidden;
      overflow-y: scroll;
      display: block;
    }
  }
}
