.wrap {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  opacity: 0;
  transform: translateY(32px);

  animation: animationAppearance 600ms linear;
  animation-fill-mode: forwards;
  animation-delay: 1000ms;

  @keyframes animationAppearance {
    0% {
      opacity: 0;
      transform: translateY(32px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
