.section {
  position: relative;
  padding: var(--marginVertical) 0;
  margin: 0 auto;
  width: 100%;
  max-width: var(--width);
  display: flex;
  flex-direction: column;
  align-items: center;
}
