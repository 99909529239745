.title {
  color: #1f380b;
  text-align: center;
  font-family: "Aeonik", sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;

  span {
    color: #83c428;
  }
}
