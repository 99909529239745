.wrap {
  margin: auto;
  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  opacity: 0;
  transform: scale(0.13);
  animation: animationBouncy 800ms linear;
  animation-delay: var(--delay);
  animation-fill-mode: forwards;

  &:after {
    z-index: -1;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(22, 155, 86, 0.07);
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  &:before {
    z-index: -1;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(22, 155, 86, 0.07);
    width: 77.2%;
    height: 77.2%;
    border-radius: 50%;
  }

  svg {
    width: 62.5%;
    height: 62.5%;
  }

  @keyframes animationBouncy {
    0% {
      opacity: 0;
      transform: scale(0.13);
    }
    70% {
      transform: scale(1);
    }
    80% {
      transform: scale(0.9);
    }
    90% {
      opacity: 1;
      transform: scale(1.1);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.iconWrap {
  color: #ffffff;
  background-color: #83c428;
  border-radius: 50%;
  width: 54.5%;
  height: 54.5%;
  display: flex;
  align-items: center;
  justify-content: center;
}
