.wrap {
  width: var(--size);
  height: var(--size);

  margin: auto;
  flex-shrink: 0;
}

.sparkleIconWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transform: rotate(var(--rotate));

  svg {
    width: 66.6%;
    height: 66.6%;

    &.glowingAnimation {
      opacity: 0;
      transform: scale(0.2);
      animation: animationGlowing 2900ms infinite;
      animation-delay: var(--delay);
    }

    &.opacityAnimation {
      opacity: 0;
      animation: animationOpacity 600ms linear;
      animation-delay: var(--delay);
      animation-fill-mode: forwards;
    }

    @keyframes animationGlowing {
      0% {
        opacity: 0;
        transform: scale(0.2);
      }
      10% {
        opacity: 0;
        transform: scale(0.2);
      }
      20% {
        opacity: 1;
        transform: scale(1);
      }
      90% {
        opacity: 1;
        transform: scale(1);
      }
      100% {
        opacity: 0;
        transform: scale(0.2);
      }
    }
    @keyframes animationOpacity {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}
